// @flow

import React from 'react'

import { Channel } from '../../models'

type Props = {
  channel: Channel
}

export function InsightsIframe ({ channel }: Props) {
  return (
    <iframe className="insights-iframe"
            src={`https://eu.holistics.io/embed/${(channel.custom_config.userInsights.holisticsEmbedCode)}?_token=${channel.holistics_token}`}/>
  )
}
