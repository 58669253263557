// @flow

import React from 'react'

import { useShop } from '../../../../hooks'
import { MessageService } from '../../../../services'

import { CCToolbar } from '../../../UI'

type Props = {
  selectedBlock: Object,
  onMessageCreated: Function,
  isAdmin: boolean
}

export function Toolbar (props: Props) {
  const [shop, , { hasCustomerServiceAiFeature }] = useShop()

  function handleClick (type: any, params: Object): Function {
    return async (): Promise<void> => {
      const message = await MessageService.createMessage({
        block_id: props.selectedBlock.id,
        ...(type && { message_type: type }),
        // $FlowFixMe
        ...(!type && { text: '' }),
        // $FlowFixMe
        ...(type && type === 'input' && { text: 'Ask subscriber for input here.' }),
        ...params
      })
      await props.onMessageCreated(message)
    }
  }

  const toolbarItems = [
    {
      id: 'new-message-button',
      onClick: handleClick(''),
      title: 'Message',
      icon: 'envelope',
      tooltip: 'Send a chat message to your subscriber. You can add buttons to a message',
      type: 'standard'
    },
    {
      id: 'new-gallery-template-button',
      onClick: handleClick('gallery'),
      title: 'Gallery',
      icon: 'th-large',
      tooltip: 'A gallery is a horizontal carousel with up to 10 elements. Each element includes a title, subtitle, image and up to three buttons',
      type: 'standard'
    },
    {
      id: 'new-upload-button',
      onClick: handleClick('upload'),
      title: 'Upload',
      icon: 'upload',
      tooltip: 'Use the file upload element to give the chatbot user the option to upload files ( pdf, gif, png, webp, jpg, and jpeg). It is especially useful for Customer Service AI Bots.',
      type: 'standard'
    },
    {
      id: 'new-image-button',
      onClick: handleClick('image'),
      title: 'Image',
      icon: 'camera',
      tooltip: 'Sends an image to a subscriber. The size should be < 500kb and the image should have a minimum of 500 pixels',
      type: 'standard'
    },
    {
      id: 'new-video-button',
      onClick: handleClick('video'),
      title: 'Video',
      icon: 'play',
      tooltip: 'Sends a video to a subscriber. You should use .mp4 and the size should be < 5mb',
      type: 'standard'
    },
    {
      id: 'new-input-button',
      onClick: handleClick('input'),
      title: 'Input',
      icon: 'keyboard-o',
      tooltip: 'Ask the subscriber an open question and save the response as an attribute. Useful to collect data like email address & phone number',
      type: 'standard'
    },
    {
      id: 'new-select',
      onClick: handleClick('select'),
      title: 'Select',
      icon: 'list',
      tooltip: 'A subscriber can select multiple attributes from a list. The list appears after the button click',
      type: 'standard'
    },
    {
      id: 'new-progress-bar',
      onClick: handleClick('progress_bar'),
      title: 'Progress Bar',
      icon: 'minus',
      tooltip: 'A progress bar that will be shown above the messages.',
      type: 'standard'
    },
    {
      id: 'new-products',
      onClick: handleClick('products'),
      title: 'Products',
      icon: 'shopping-cart',
      tooltip: 'Recommend products to the subscriber.',
      type: 'standard'
    },
    {
      id: 'new-products-routine',
      onClick: handleClick('products', { data: { product: { is_routine: true } } }),
      title: 'Routine',
      icon: 'cart-plus',
      tooltip: 'Recommend products to the subscriber as a routine.',
      type: 'standard'
    },
    {
      id: 'new-live-chat',
      onClick: handleClick('live_chat'),
      title: 'Live Chat',
      icon: 'commenting-o',
      tooltip: 'Forward the subscriber to your live chat.',
      type: 'standard'
    },
    {
      id: 'new-customer-service',
      onClick: handleClick('customer_service'),
      title: 'Customer Service',
      icon: 'android',
      tooltip: '',
      type: 'standard',
      hide: !hasCustomerServiceAiFeature()
    },
    {
      id: 'new-order-status',
      onClick: handleClick('order_status'),
      title: 'Order Status',
      icon: 'truck',
      tooltip: shop.hasValidIntegration()
        ? 'Use this element to fetch the order status from an integrated shop system.'
        : 'To enable this element, add a shop system integration in the shop settings.',
      type: 'logic',
      hide: !hasCustomerServiceAiFeature(),
      disabled: !shop.hasValidIntegration()
    },
    {
      id: 'new-time-delay-button',
      onClick: handleClick('timing'),
      title: 'Time Delay',
      icon: 'clock-o',
      tooltip: 'Delays the following message. Useful if you want to give the subscriber some time to open a website.',
      type: 'logic'
    },
    {
      id: 'new-send-to-block-campaign-button',
      onClick: handleClick('routing'),
      title: 'Send To Block/Chatbot',
      icon: 'exchange',
      tooltip: 'Setup rules to send your subscriber to different blocks. You can also send him to the start block of a different campaign',
      type: 'logic'
    },
    {
      id: 'new-ab-test-button',
      onClick: handleClick('ab_routing'),
      title: 'A/B Test',
      icon: 'exchange',
      tooltip: ' The A/B Test element routes subscriber to different blocks in order to test the performance of these blocks against each other',
      type: 'logic'
    },
    {
      id: 'new-webhook-button',
      onClick: handleClick('webhook'),
      title: 'Webhook',
      icon: 'arrow-right',
      tooltip: 'Use this element to send subscriber data to your system. (via POST request)',
      type: 'logic'
    },
    {
      id: 'new-custom-conversion-button',
      onClick: handleClick('custom_conversion'),
      title: 'Conversion',
      icon: 'check',
      tooltip: 'Create a custom conversion event',
      type: 'logic',
      hide: !props.isAdmin
    },
    {
      id: 'new-custom-code',
      onClick: handleClick('code'),
      title: 'Custom Code',
      icon: 'codepen',
      tooltip: 'Execute custom code to set subscriber attributes',
      type: 'logic',
      hide: !props.isAdmin
    },
    {
      id: 'new-javascript-code',
      onClick: handleClick('javascript'),
      title: 'Javascript',
      icon: 'code',
      tooltip: 'Use this element to run custom JavaScript on the website',
      type: 'logic'
    },
    {
      id: 'new-google-analytics',
      onClick: handleClick('google_analytics'),
      title: 'GA',
      icon: 'google',
      tooltip: 'Use this element to fire custom Google Analytics events',
      type: 'logic'
    },
    {
      id: 'new-edit-subscriber-attributes',
      onClick: handleClick('edit_subscriber_attributes'),
      title: 'Edit User Attributes',
      icon: 'tag',
      tooltip: 'Use this element to set/reset values for subscriber attributes',
      type: 'logic'
    },
    {
      id: 'email',
      onClick: handleClick('email'),
      title: 'Email',
      icon: 'envelope',
      tooltip: 'Use this element to send email',
      type: 'logic'
    }
  ]

  return (
    <CCToolbar
      toolbarItems={toolbarItems}
      standardItemsTitle="Message Elements"
      logicItemsTitle="Logic Elements"
    />
  )
}
