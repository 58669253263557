// @flow

import React, { useEffect, useState } from 'react'

import { KnowledgeItem } from '../../../../models'
import { useKnowledgeItems, useModal, useShop } from '../../../../hooks'

import { CCButton, CCCheckbox, CCIconButton, CCAlert, CCModal, TrboSubSegment, TrboOutsideClick, showInfoMessage } from '../../../UI'

import './KnowledgeBaseDeletion.scss'

type Props = {
  knowledgeItems: Array<KnowledgeItem>
}

export function KnowledgeBaseDeletion ({ knowledgeItems }: Props) {
  const [shop] = useShop()
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [csv, setCsv] = useState(false)
  const [manual, setManual] = useState(false)
  const [feed, setFeed] = useState(false)
  const [isDeleteModalOpen, , onDeleteRequestOpen, onDeleteRequestClose] = useModal()
  const { deleteKnowledgeItems, loadKnowledgeItems, loadInputSourceCount, inputSourceCount } = useKnowledgeItems()

  useEffect(() => {
    if (!shop.secure_id) return

    loadInputSourceCount()
  }, [shop])

  async function onSave () {
    const inputSources = []

    if (csv) inputSources.push('csv')
    if (manual) inputSources.push('manual')
    if (feed) inputSources.push('feed_link')

    if (inputSources.length > 0) {
      if (await deleteKnowledgeItems(inputSources)) {
        showInfoMessage('Selected Q&A entries deleted')
        loadKnowledgeItems()
        loadInputSourceCount()
      }
    }
    onDeleteRequestClose()
    setCsv(false)
    setManual(false)
    setFeed(false)
  }

  return (
    <TrboOutsideClick
      isEnabled={dropdownOpen}
      onOutsideClick={() => setDropdownOpen(false)}
    >
      <div className="kb__selects_container" id="selectsContainer">
        <CCIconButton
          iconName="ellipsis"
          iconVersion="6"
          iconWeight="regular"
          onClick={(e) => setDropdownOpen(!dropdownOpen)}
        />
        {dropdownOpen && (
          <div
            className="kb__selects_dropdown"
          >
            <CCButton
              icon="trash"
              iconVersion="6"
              iconWeight="regular"
              onClick={() => {
                setDropdownOpen(false)
                onDeleteRequestOpen()
              }}
            >
              Delete all
            </CCButton>
          </div>
        )}
        {isDeleteModalOpen &&
          <CCModal
            hasNewStyle
            isOpen
            className="delete-entries-modal"
            onRequestClose={onDeleteRequestClose}
            title="Delete all entries"
            description="Select what data source you want to delete"
            actions
            cancelButtonText="Cancel"
            saveButtonText="Delete"
            saveButtonColor="red"
            saveButtonDisabled={!csv && !manual && !feed}
            onSave={onSave}
            width={900}
          >
            <>
                <CCAlert
                  type="warning"
                  inline
                  header="Important!"
                  content="By deleting data sources, all of your entries will be permanently deleted and this cannot be undone"
                />
                <TrboSubSegment customClasses="kb-delete__checkbox--container">
                  <CCCheckbox
                    type="checkbox"
                    checked={csv}
                    className="checkbox"
                    label="All .csv files"
                    onChange={() => setCsv(!csv)}
                    counterLabel={inputSourceCount?.csv}
                    disabled={!inputSourceCount.csv}
                  />
                </TrboSubSegment>
                <TrboSubSegment customClasses="kb-delete__checkbox--container">
                  <CCCheckbox
                    type="checkbox"
                    checked={manual}
                    className="checkbox"
                    label="All manual entries"
                    onChange={() => setManual(!manual)}
                    counterLabel={inputSourceCount?.manual}
                    disabled={!inputSourceCount.manual}
                  />
                </TrboSubSegment>
                <TrboSubSegment customClasses="kb-delete__checkbox--container">
                  <CCCheckbox
                    type="checkbox"
                    checked={feed}
                    className="checkbox"
                    label="Imported feed (via link)"
                    onChange={() => setFeed(!feed)}
                    counterLabel={inputSourceCount?.feed_link}
                    disabled={!inputSourceCount.feed_link}
                  />
                </TrboSubSegment>
              </>
          </CCModal>
        }
      </div>
    </TrboOutsideClick>
  )
}
