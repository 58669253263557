// @flow

import React from 'react'

import { Channel } from '../../../models'

type Props = {
  channel: Channel
}

export function ChannelPanel ({ channel }: Props) {
  return (
    <div className="pipeline-entry" data-facebook-page-id={channel.id}>
      <div className="row">
        <div className="col-md-10">
          <div className="buttons">
            {/* $FlowFixMe */}
            <a className="status" href={channel.url}>
              <i className="fa fa-user"/>
            </a>
          </div>
          <strong className="title">
            {channel.name}
          </strong>
        </div>
      </div>
    </div>
  )
}
