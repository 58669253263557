// @flow

import React, { useState } from 'react'
import { CSVReader } from 'react-papaparse'

import { CCButton, CCModal } from '../UI'
import { ProductsService } from '../../services'
import { useShop } from '../../hooks'
import { ImportAttributesList } from './ImportAttributesList'
import { ImportEnableDisableProducts } from './ImportEnableDisableProducts'

type Props = {
  isOpen: boolean,
  onRequestClose: Function
}

export function ImportProductsModal ({ isOpen, onRequestClose }: Props) {
  const [shop] = useShop()
  const [file, setFile] = useState(null)
  const [progress, setProgress] = useState(null)
  const [disableExistingProducts, setDisableExistingProducts] = useState(true)
  const [enableNewProducts, setEnableNewProducts] = useState(true)
  const [disabledAttributes, setDisabledAttributes] = useState([])

  const handleOnDrop = (data, file) => setFile(file)

  const handleOnError = () => {}

  const handleOnRemoveFile = () => setFile(null)

  const handleImport = async () => {
    if (!file) return

    const formData = new FormData()
    formData.append('file', file)
    formData.append('disable_old_products', disableExistingProducts.toString())
    formData.append('enable_new_products', enableNewProducts.toString())
    disabledAttributes.forEach(attribute => formData.append('disabled_attributes[]', attribute.toString()))
    await ProductsService.importProducts(shop, formData, progress => setProgress(progress))
    onRequestClose()
    window.location.reload()
  }

  return (
    <CCModal title="Import products from CSV (Facebook Product Feed) or XML (Google Products)" width={700}
             isOpen={isOpen}>
      <div className="cc-modal__field">
        <div className="cc-modal__horizontal-input">
          <div className="cc-modal__field__label">
            Product Feed:
          </div>
          <div className="cc-modal__field__body">
            <CSVReader
              accept={['text/csv, .csv, application/vnd.ms-excel', '.xml', '.txt']}
              onDrop={handleOnDrop}
              onError={handleOnError}
              addRemoveButton
              onRemoveFile={handleOnRemoveFile}
            >
              <span>Drop CSV or XML file here or click to upload.</span>
            </CSVReader>
          </div>
        </div>
        <ImportEnableDisableProducts
          disableExistingProducts={disableExistingProducts}
          disableExistingProductsOnChange={setDisableExistingProducts}
          enableNewProducts={enableNewProducts}
          enableNewProductsOnChange={setEnableNewProducts}
        />
        <ImportAttributesList
          onChange={setDisabledAttributes}
          disabledAttributes={disabledAttributes}
        />
      </div>
      <div className="cc-modal__footer">
        <CCButton onClick={onRequestClose}>
          Cancel
        </CCButton>

        <CCButton disabled={!file || !!progress} color="primary" onClick={handleImport}>
          {(progress ? `Importing... (${progress}%)` : 'Import')}
        </CCButton>
      </div>
    </CCModal>
  )
}
