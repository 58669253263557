// @flow

import _ from 'lodash'
import React, { useState, useEffect } from 'react'

import { type MultipleInputItem } from '@chatchamp/chatchamp-types'

import { CCButton, CCModal } from '../../../UI'
import { InputForm } from './InputForm'

type Props = {
  input: MultipleInputItem,
  handleSave: Function,
  isOpen: boolean,
  onRequestClose: Function
}

export function MultipleInputModal ({
  input,
  handleSave,
  isOpen,
  onRequestClose
}: Props) {
  const [_input, setInput] = useState({ ...input })
  const [isLoading, setIsLoading] = useState(false)

  function propertyChangeHandler (property: string, value: any) {
    _.set(_input, property, value)
    setInput({ ..._input })
  }

  async function saveHandler (): any {
    setIsLoading(true)
    await handleSave(_input)
    setIsLoading(false)

    onRequestClose()
  }

  function handleInputChange (property: string, value: any): void {
    propertyChangeHandler(property, value)
  }

  useEffect(() => setInput({ ...input }), [input])

  return (
    <CCModal title={_input.__isNew__ ? 'Add input' : 'Edit input'} isOpen={isOpen}>
      <InputForm input={_input} onChange={handleInputChange} />

      <div className="cc-modal__footer">
        <CCButton onClick={onRequestClose}>
          Cancel
        </CCButton>

        <CCButton
          color="primary"
          onClick={saveHandler}
          disabled={!_input.label || isLoading}
        >
          {isLoading ? 'Saving ' : 'Save'}
          {isLoading && <i className="fa fa-refresh fa-spin"/>}
        </CCButton>
      </div>
    </CCModal>
  )
}
