// @flow

import React, { useState } from 'react'
import _ from 'lodash'

import { BlockService, MessageService } from '../../../services'
import { guid } from '../../../utils/uuid'
import { CCPanel } from '../../UI'
import { ABTestEntry } from './ABTestEntry'

type Props = {
  routing: Array<Object>,
  blocks: Array<Object>,
  message: Object,
  onBlocksChange: Function,
  campaign: Object
}

type RoutingHashItem = {
  blockId: ?string,
  percentage: ?string,
  uuid?: ?string,
  index?: ?number
}

function buildRoutingHash (routing: Array<RoutingHashItem>): Array<Object> {
  return _
    .chain(routing)
    .map(({ percentage, blockId }, index) => ({
      percentage,
      blockId,
      uuid: guid(),
      index
    }))
    .sortBy('index')
    .value()
}

export function ABTest (props: Props) {
  const [routingHash, setRoutingHash] = useState(buildRoutingHash(props.routing))
  const [errors, setErrors] = useState('')
  const [notices, setNotices] = useState('')
  const percentageOptions = [100, 50, 33.33, 25, 20, 16.6, 12, 10, 5].map((n) => ({
    title: `${n}%`,
    value: `${n}`
  }))

  function isValidPercentage (routingHash: Array<RoutingHashItem>): boolean {
    const percentage = routingHash.reduce((previousValue, currentItem) => previousValue + +currentItem.percentage, 0)

    if (percentage > 100) {
      setErrors('Percentages sum has to be less than or equal 100. Changes will not be saved.')
      setNotices('')
      return false
    } else if (percentage < 98) {
      setErrors('')
      setNotices('Total percentage is less than 100%')
      return true
    } else {
      setErrors('')
      setNotices('')
      return true
    }
  }

  function buildBlockOptions () {
    const otherBlocks = props.blocks.filter(e => e.id !== props.message.block_id)

    // $FlowFixMe
    return _.map(otherBlocks, (block) => ({
      value: block.id + '',
      title: block.name
    }))
  }

  // Extract entries where both block id and tag id are set
  function getTruthyHashEntries (routingHash: Array<RoutingHashItem>) {
    const entries = _
      .chain(routingHash)
      .filter((routingEntry) => !!routingEntry.percentage && !!routingEntry.blockId)
      .map(({ percentage, blockId }) => ({ percentage, blockId }))
      .value()
    return entries
  }

  async function updateMessage (newRoutingHash: Array<RoutingHashItem>) {
    setRoutingHash(newRoutingHash)
    const truthyEntries = getTruthyHashEntries(newRoutingHash)
    if (isValidPercentage(newRoutingHash) && (Object.keys(truthyEntries).length || !newRoutingHash.length)) {
      const { message, campaign, onBlocksChange } = props
      await MessageService.updateMessage(message.id, { routing: truthyEntries })
      const blocks = await BlockService.getBlocks(campaign.id)
      await onBlocksChange(blocks)
    }
  }

  async function handleEntryChange (entry: any) {
    const { uuid } = entry
    const routingHashClone = [...routingHash]
    _.remove(routingHashClone, { uuid })
    routingHashClone.push(entry)
    await updateMessage(_.sortBy(routingHashClone, 'index'))
  }

  async function handleEntryDelete (entry: any) {
    const { uuid } = entry
    const routingHashClone = [...routingHash]
    _.remove(routingHashClone, { uuid })
    // $FlowFixMe
    const indexedRoutingHashClone = _.map(routingHashClone, (row, index) => ({
      ...row,
      index
    }))
    await updateMessage(_.sortBy(indexedRoutingHashClone, 'index'))
  }

  function renderHashItems () {
    // $FlowFixMe
    return _.map(routingHash, (entry) => {
      return (
        <ABTestEntry
          percentageOptions={percentageOptions}
          blockOptions={buildBlockOptions()}
          onChange={handleEntryChange}
          onDelete={handleEntryDelete}
          key={entry.uuid}
          entry={entry}
        />
      )
    })
  }

  function renderEmptyState () {
    return (
      <div className="send-to-block__empty">
        <p>
          <i className="fa fa-info-circle"/>
        </p>
        <p>
          A/B Test sends percentages of users to different blocks
        </p>
        <p>
          Start by clicking <b>ADD NEW RULE</b> below
        </p>
      </div>
    )
  }

  function renderNotices () {
    return notices && routingHash.length
      ? (
        <div className="send-to-block__notice animate">
          <div className="animated fadeIn">
            <i className="fa fa-warning"/>
            {notices}
          </div>
        </div>
        )
      : null
  }

  function handleAddRuleClick () {
    setRoutingHash([
      ...routingHash,
      {
        percentage: null,
        blockId: null,
        uuid: guid(),
        index: routingHash.length
      }
    ])
  }

  function renderErrors () {
    return errors && routingHash.length
      ? (
        <div className="send-to-block__error">
          <div className="animated shake">
            <i className="fa fa-ban"/>
            {errors}
          </div>
        </div>
        )
      : null
  }

  return (
    <div className="send-to-block">
      <CCPanel
        title="A/B Test Blocks"
        description="The A/B Test element routes subscriber to different blocks in order to test the performance of these blocks against each other."
        bottomButtonTitle="ADD NEW RULE"
        bottomButtonIcon="plus"
        bottomButtonDisabled={routingHash.length >= percentageOptions.length}
        onBottomButtonClick={handleAddRuleClick}
        noPadding
      >
        {renderErrors()}
        {renderNotices()}
        <div className="send-to-block__content">
          {routingHash.length
            ? renderHashItems()
            : renderEmptyState()}
        </div>
      </CCPanel>
    </div>
  )
}
