// @flow

import React from 'react'

import './ItemsByDepth.scss'

type Props = {
  items: Array<Object>,
  depth: string,
  isSelected: boolean,
  isLastDepth: boolean,
  isDeletable: boolean,
  onDeleteItem: Function,
  onClickItem: Function
}

export function ItemsByDepth (props: Props) {
  const { items, depth, isSelected, isLastDepth, isDeletable, onDeleteItem, onClickItem } = props

  function depthClass () {
    if (isLastDepth && depth !== '0') {
      return 'item-depth-line-top'
    } else if (!isLastDepth && depth === '0') {
      return 'item-depth-line-bottom'
    } else if (isLastDepth && depth === '0') {
      return ''
    }

    return 'item-depth-line-middle'
  }

  function renderDepth () {
    return (
      <div className={`pages-list-empty-depth ${depthClass()}`}>
        <div
          className={`item-depth ${depth === 'null' ? ' item-depth-null ' : ''} ${isSelected ? ' item-selected-border ' : ''}`}>
          {depth === 'null'
            ? (<div>
                <span className="item-depth-tooltiptext">These pages are not reachable</span>
                <i className="item-depth-null-tooltip-icon fa fa-warning"/>
              </div>)
            : parseInt(depth) + 1}</div>
      </div>
    )
  }

  function renderDeleteIcon (item: Object) {
    if (!isDeletable) return ''

    return <i className="item-delete-icon fa fa-times" onClick={() => onDeleteItem(item)}/>
  }

  function renderPagesName () {
    return (
      <div className="pages-list-name-wrapper">
        {items.map(item => (
          <div
            key={item.id}
            onClick={() => onClickItem(item.id)}
            className={`item-name block.id ${item.isSelected ? 'item-selected-border' : ''}`}>
            {renderDeleteIcon(item)}
            <span className="item-name-multiline-ellipsis" role="item-by-depth-name">{item.name}</span>
          </div>
        ))}
      </div>
    )
  }

  return (
    <div>
      <div className="display-flex">
        {renderDepth()}
        {renderPagesName()}
      </div>
      {!isLastDepth
        ? (<div className="display-flex">
            <div className="item-line-spacing"/>
          </div>)
        : null}
    </div>
  )
}
