// @flow
import React, { useEffect, useState } from 'react'
import { type RoutingParams, ruleOperators, conditionOperators } from '@chatchamp/chatchamp-types'
import toastr from 'toastr'
import { BlockService, MessageService } from '../../../services'
import { CCPanel } from '../../UI'
import { Campaign, Message, Wizard, type Block } from '../../../models'
import './RoutingComponentV2.scss'
import { Rule } from './RoutingComponentV2/Rule'
import { Else } from './RoutingComponentV2/Else'
import { RuleValidation } from './RoutingComponentV2/helpers/RuleValidation'

export type Item = {
  label: string,
  value?: string,
  options?: Array<Item>,
  itemName?: 'blockId' | 'campaignId'
}

type RulesType = $PropertyType<$PropertyType<RoutingParams, 'rules'>, '0'>

type Props = {
  routing: RoutingParams,
  tags: Array<{ id: string, name: string }>,
  message: Message,
  campaign: Campaign | Wizard,
  description: string,
  panelTitle: string,
  type: string,
  itemOptions: Array<Item>,
  onBlocksChange: (blocks: Array<Block>) => void,
  onError?: (error: string) => void
}

export function RoutingComponentV2 (props: Props) {
  const [rules, setRules] = useState(props.routing.rules || [])
  const [elseFallback, setElseFallback] = useState(props.routing.elseFallback)

  useEffect(() => {
    const errors = []

    if (!elseFallback?.id) errors.push('Go To target needs to be set.')

    const hasAnyRuleWithNoRoutingTarget = rules?.some((rule) => {
      return new RuleValidation(rule).hasRoutingTarget()
    })
    if (rules.length && !hasAnyRuleWithNoRoutingTarget) errors.push('Every rule needs to have a routing target.')

    const errorMessage = errors.reduce((acc, error) => `${acc}${error}`, '')
    props.onError?.(errorMessage)
  }, [elseFallback?.id, rules])

  async function updateRules (newRules: $PropertyType<RoutingParams, 'rules'>) {
    await save({
      elseFallback,
      rules: newRules
    })
    setRules(newRules)
  }

  function handleAddRule () {
    const newRules = [...(rules || []), {
      routingTarget: { },
      operator: ruleOperators.ALL,
      conditions: [{
        attributeId: null,
        operator: conditionOperators.EQUALS,
        expected: null
      }]
    }]

    setRules(newRules)
  }

  async function handleChangeRule (newRule: RulesType, idx: number) {
    const newRules = rules.map((r, rIdx) => {
      if (rIdx === idx) {
        return newRule
      }
      return r
    })

    setRules(newRules)

    const isRuleValid = new RuleValidation(newRule).isValid()
    if (isRuleValid) {
      await updateRules(newRules)
    }
  }

  async function handleChangeElseTargetRoute (routingTarget) {
    setElseFallback(routingTarget)
    await save({
      ...props.routing,
      rules,
      elseFallback: routingTarget
    })
  }

  async function save (routing: RoutingParams) {
    try {
      await MessageService.updateMessage(props.message.id, {
        data: {
          ...props.message.data,
          routing
        }
      })
      toastr.remove()
      toastr.success('Updated Go To Element.')
    } catch (err) {
      toastr.error(`Could not update Go To Element. (${err.message})`)
    }
    const blocks = await BlockService.getBlocks(props.campaign.id)
    await props.onBlocksChange(blocks)
  }

  async function handleDeleteRule (idx) {
    const newRules = rules.filter((rule, rIdx) => {
      return rIdx !== idx
    })

    await updateRules(newRules)
  }

  const hasRules = rules.length

  return (
    <div className="send-to-block">
      <CCPanel
        title={props.panelTitle}
        description={props.description}
        bottomButtonTitle='ADD NEW RULE'
        bottomButtonIcon="plus"
        onBottomButtonClick={handleAddRule}
        noPadding
      >
        <div className="send-to-block__content">
          {Boolean(rules.length) &&
            (<>
            {rules.map((rule, idx) => (
              <Rule
                variant={idx === 0 ? 'if' : 'elseif'}
                key={idx}
                rule={rule}
                attributes={props.tags}
                itemOptions={props.itemOptions}
                routingTarget={rule.routingTarget}
                onDelete={() => handleDeleteRule(idx)}
                onChange={(r) => handleChangeRule(r, idx)}
              />
            ))}
            </>)}
          <Else
            center={!hasRules}
            routingTarget={elseFallback}
            itemOptions={props.itemOptions}
            onChangeRoutingTarget={handleChangeElseTargetRoute}
          />
        </div>
      </CCPanel>
    </div>)
}
