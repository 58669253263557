// @flow

import React from 'react'

import { BaseSettingsPage } from '../../../Settings/BaseSettingsPage'

import { ChatbotSettingsPanels } from './ChatbotSettingsPanels'

export default function ChatbotSettings () {
  return (
    <BaseSettingsPage
      title="Chatbot Settings"
      description={
        `<span>Style your chatbot and choose on which pages you want to show your chatbots.</span>
        <span>Check out our user guide
          <a target="_blank"
            rel="noopener noreferrer"
            href="https://guide.chatchamp.com/Chatbot-Settings-4b3bef75055e447db5350fdf30ce0fe6">
            chatbot user guide
          </a> to learn more.
        </span>`}
>
      <ChatbotSettingsPanels/>
    </BaseSettingsPage>
  )
}
