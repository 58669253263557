// @flow

import React from 'react'

import { CCToolbar, type ToolbarItem } from '../../../UI/CCToolbar/CCToolbar'

import './Toolbar.scss'

type Props = {
  onClickItem: Function
}

export function Toolbar (props: Props) {
  const { onClickItem } = props
  const toolbarItems: Array<ToolbarItem> = [
    {
      id: 'send-to-page-element',
      onClick: onClickItem('routing'),
      title: 'Send To Page',
      icon: 'exchange',
      tooltip: 'Setup rules to send your users to different pages.',
      type: 'logic'
    },
    {
      id: 'edit-subscriber-attributes-element',
      onClick: onClickItem('edit_subscriber_attributes'),
      title: 'Edit User Attributes',
      icon: 'tag',
      tooltip: 'Use this element to set/reset values for subscriber attributes',
      type: 'logic'
    },
    {
      id: 'webhook-element',
      onClick: onClickItem('webhook'),
      title: 'Webhook',
      icon: 'arrow-right',
      tooltip: 'Use this element to fire a POST request to an endpoint',
      type: 'logic'
    },
    {
      id: 'new-javascript-code',
      onClick: onClickItem('javascript'),
      title: 'Javascript',
      icon: 'code',
      tooltip: 'Use this element to run custom JavaScript on the website',
      type: 'logic'
    },
    {
      id: 'new-custom-code',
      onClick: onClickItem('code'),
      title: 'Code',
      icon: 'codepen',
      tooltip: 'Selecting this option there will create a Custom Code element',
      type: 'logic'
    },
    {
      id: 'email',
      onClick: onClickItem('email'),
      title: 'Email',
      icon: 'envelope',
      tooltip: 'Use this element to send email',
      type: 'logic'
    }
  ]

  return (
    <div className="wizard-builder-toolbar">
      <CCToolbar
        toolbarItems={toolbarItems}
        logicItemsTitle="Logic Elements"
      />
    </div>
  )
}
