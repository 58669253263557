// @flow

import React from 'react'

type Props = {
  depth: string
}

export function BlockDepth ({ depth }: Props) {
  if (depth === 'null') {
    return (
      <div>
        <span className="blocks-tooltiptext">These blocks are not reachable</span>
        <i className="blocks-null fa fa-warning"/>
      </div>
    )
  }

  return parseInt(depth) + 1
}
