// @flow

import React, { useMemo } from 'react'

import { DateFormatter } from '../../../utils/dateFormatter'

import './InvalidWarning.scss'

type Props = {
  invalidatedAt: ?Date,
  reasons: ?Array<string>
}

export function InvalidWarning (props: Props) {
  const invalidatedAt = useMemo(() => {
    if (!props.invalidatedAt) {
      return ''
    }
    return new DateFormatter(props.invalidatedAt).format()
  }, [props.invalidatedAt])

  return (
    <div
      className='invalid-warning'
    >
      <div>
        Invalidated at
      </div>
      <div>
        {invalidatedAt}
      </div>
      <div>Reasons</div>
      <ul>
        {props.reasons && props.reasons.map((reason, idx) => (
            <li key={idx}>
              {reason}
            </li>))
        }
      </ul>
    </div>
  )
}
