// @flow

import React from 'react'
import _ from 'lodash'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

import { ProductRecommenderTypes } from '@chatchamp/chatchamp-types'

import { useAttributes, useCategories, useMessage } from '../../../hooks'
import { Message } from '../../../models'

import { CCPanel } from '../../UI'

import { type Context, contexts } from '../Context'

import { BadgePanel } from './BadgePanel/BadgePanel'
import { LayoutPanel } from './LayoutPanel/LayoutPanel'
import { OtherPanel } from './OtherPanel/OtherPanel'
import { RoutinePanel } from './RoutinePanel/RoutinePanel'
import { RecommenderPanel } from './RecommenderPanel/RecommenderPanel'

import './ProductsPanel.scss'

type Props = {
  message: Message,
  isAdmin: boolean,
  onBlocksChange: Function,
  context: Context
}

export function ProductsPanel ({
  message: propMessage = new Message({ data: {} }),
  isAdmin = false,
  onBlocksChange = () => {},
  context = contexts.CHATBOT
}: Props) {
  const [message, , handlePropertyChange] = useMessage(propMessage)
  const [attributeOptions, attributes] = useAttributes()
  const [categoryOptions] = useCategories()

  function handleMessageChange (param: any): any {
    return (value: any): ?Promise<Message> => {
      const prevValue = _.get(message, param)
      if (value === prevValue) return

      return handlePropertyChange(param, false)(value)
    }
  }

  function panelTitle (): string {
    return message.isRoutine() ? 'Routine' : 'Products'
  }

  return <CCPanel
    noBottomContent title={panelTitle()}
    description="Recommend products based on the subscriber's preferences.">
    <Tabs>
      <TabList>
        {message.isRoutine() && <Tab>Routine Configuration</Tab>}
        <Tab>Layout</Tab>
        <Tab>Recommender</Tab>
        {!message.isRoutine() && message.data.product?.recommender_type !== ProductRecommenderTypes.SPECIFIC && (
          <Tab disabled={message.isConsideringOrderOfCategories()}>Badge</Tab>
        )}
        <Tab>Other</Tab>
      </TabList>
      {message.isRoutine() && (
        <TabPanel>
          <RoutinePanel
            message={message}
            onMessageChange={handleMessageChange}
            categoryOptions={categoryOptions}
          />
        </TabPanel>
      )}
      <TabPanel>
        <LayoutPanel
          context={context}
          message={message}
          onMessageChange={handleMessageChange}
          attributes={attributes}
        />
      </TabPanel>
      <TabPanel>
        <RecommenderPanel
          context={context}
          message={message}
          onMessageChange={handleMessageChange}
          attributeOptions={attributeOptions}
          categoryOptions={categoryOptions}
        />
      </TabPanel>
      {message.data.product?.recommender_type !== ProductRecommenderTypes.SPECIFIC &&
        !message.isRoutine() && <TabPanel>
          <BadgePanel
            message={message}
            onMessageChange={handleMessageChange}
          />
      </TabPanel>}
      <TabPanel>
        <OtherPanel
          context={context}
          message={message}
          onMessageChange={handleMessageChange}
          isAdmin={isAdmin}
          onBlocksChange={onBlocksChange}
        />
      </TabPanel>
    </Tabs>
  </CCPanel>
}
