// @flow

import React from 'react'

import { FeatureFlag } from '../../../../FeatureFlag/FeatureFlag'
import { type KnowledgeDataSourceType } from '../../../../../models'

import { DataSourceDropdownItem } from './DataSourceDropdownItem'

import './DataSourceDropdown.scss'

type Props = {
  onSelect: (type: KnowledgeDataSourceType) => void
}

export function DataSourceDropdown ({ onSelect }: Props) {
  function handleSelect (type: KnowledgeDataSourceType) {
    return () => {
      onSelect(type)
    }
  }

  return (
    <div className="kb-data-source-dropdown">
      <div className="kb-data-source-dropdown__wrapper">
        <DataSourceDropdownItem
          icon="message-dots"
          color="green"
          title="Q&A"
          description="Via feed link, .CSV file or add manually"
          onClick={handleSelect('qa')}
        />
        <FeatureFlag showOnStaging>
          <DataSourceDropdownItem
            icon="globe-pointer"
            color="blue"
            title="Website"
            description="Enter URLs or sitemaps for AI training."
            onClick={handleSelect('website')}
          />
        </FeatureFlag>
        <FeatureFlag showOnProduction>
          <DataSourceDropdownItem
            isDisabled
            icon="globe-pointer"
            color="blue"
            title="Website (Coming soon)"
            description="Enter URLs or sitemaps for AI training."
            onClick={handleSelect('website')}
          />
        </FeatureFlag>
        {/* <DataSourceDropdownItem
          isDisabled
          icon="file"
          color="orange"
          title="File (Coming soon)"
          description="DataSource PDFs or DOCXs for AI learning."
          onClick={handleSelect('file')}
        />
        <DataSourceDropdownItem
          isDisabled
          icon="pen-to-square"
          color="gray"
          title="Text (Coming soon)"
          description="Add sources in plain text format for AI training."
          onClick={handleSelect('text')}
        /> */}
      </div>
    </div>
  )
}
