// @flow

import React from 'react'

import { TrackingProviders } from '@chatchamp/chatchamp-types'

import { CCDropdown, CCTextInput, type DropdownOption } from '../../../UI'

import { Shop } from '../../../../models'

import { CustomDataLayer, GaPanel, MatomoPanel, TealiumPanel } from './providers'

type Props = {
  shop: Shop,
  onShopChange: Function
}

export function TrackingPanel ({ shop, onShopChange }: Props) {
  return (
    <div className="settings-item-wrapper">
      <h2 className="settings-header">Tracking</h2>
      <p className="settings-text">
        Provide your Google Analytics ID and Facebook Pixel ID so that you can track different events. <a
        href="https://developer.chatchamp.com/#section/Website-Chat"
        target="_blank"
        rel="noopener noreferrer"
      >
        Read more here.
      </a>
      </p>
      <h3 className="settings-subheader">
        Facebook Pixel ID
      </h3>
      <CCTextInput
        customClasses="settings-input"
        onChange={(val) => onShopChange('chat_bubble.facebookPixelId', val)}
        placeholder="Enter the Facebook Pixel ID"
        value={shop.chat_bubble.facebookPixelId}
      />
      <h3 className="settings-subheader">
        Tracking Provider
      </h3>
      <div className="settings-input">
        <CCDropdown
          onChange={(option: DropdownOption) => onShopChange('chat_bubble.tracking.trackingProvider', option.value)}
          value={shop.chat_bubble.tracking?.trackingProvider || Object.keys(TrackingProviders)[0]}
          options={Object.entries(TrackingProviders).map(([key, value]) => { return { value: key, label: value } })}
        />
      </div>
      {(shop.trackingProvider() === 'CustomDataLayer') && (<CustomDataLayer shop={shop} onChange={onShopChange}/>)}
      {(shop.trackingProvider() === 'GA') && (<GaPanel shop={shop} onChange={onShopChange}/>)}
      {(shop.trackingProvider() === 'Tealium') && (<TealiumPanel shop={shop} onChange={onShopChange}/>)}
      {(shop.trackingProvider() === 'Matomo') && (<MatomoPanel shop={shop} onChange={onShopChange}/>)}
    </div>
  )
}
