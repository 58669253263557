// @flow

import React from 'react'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'

import { InputData } from '@chatchamp/chatchamp-types'

const SortHandle = SortableHandle(() => <i className="sortable-option-preview__sort-handle fa fa-bars"/>)

type Props = {
  optionType: string,
  onClick: Function,
  onDelete: Function,
  item: $Shape<InputData>,
  disableDeleteButtons: boolean
}

export const SortableMultipleInputItem = SortableElement(({
  optionType,
  onClick,
  onDelete,
  item,
  disableDeleteButtons
}: Props) => {
  function handleDelete (event) {
    event.stopPropagation()

    if (!confirm('Are you sure?')) return

    onDelete(item)
  }

  return (
    <div
      onClick={onClick(item)}
      className="sortable-option-preview"
    >
      <SortHandle/>
      <div className="sortable-option-preview__item">
        <div className="single-choice-option-preview">
          <div className="single-choice-option-preview__title">
            {item.label}
          </div>
        </div>
      </div>
      {!disableDeleteButtons && (
        <span
          onClick={handleDelete}
          className="sortable-option-preview__delete-handle"
        ><i className="fa fa-remove"/></span>
      )}
    </div>
  )
})
