// @flow

import React from 'react'
import ReactTooltip from 'react-tooltip'

import { type Block } from '../../models'

import { BlocksStore } from '../../storeModels/BlocksStore'
import { BlockButton } from './BlockButton'

import './BlocksChooser.scss'

type Props = {
  blocks: Array<Block>,
  selectedBlock: Block,
  handleBlockClick: Function
}

export function BlocksChooser ({ blocks, selectedBlock, handleBlockClick }: Props) {
  const blocksByDepth = BlocksStore.clusterByDepth(blocks)

  function renderBlock (block: Block) {
    return <BlockButton
      key={block.id}
      selected={selectedBlock.id === block.id}
      block={block}
      onClick={handleBlockClick}
    />
  }

  function renderDepth (depth: string) {
    return (<div key={`depth-${depth}`} className="campaign-blocks__depth">
      <div className="depth-label">
        {depth === 'null'
          ? <i
            className="fa fa-warning" style={{ color: '#CE505F' }}
            data-tip="These blocks are not connected to another block and can never be reached by the subscriber. Connect it using a button or the send to block element."/>
          : depth}
      </div>
      {blocksByDepth[depth].map(block => renderBlock(block))}
    </div>)
  }

  return (
    <div>
      <div className="campaign-blocks">
        {Object.keys(blocksByDepth).map(depth => renderDepth(depth))}
      </div>
      <ReactTooltip delayShow={1000}/>
    </div>
  )
}
