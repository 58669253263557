// @flow

import React, { useEffect, useState } from 'react'

import { type KnowledgeDataSourceType } from '../../../../models'
import { useKnowledgeItems } from '../../../../hooks/useKnowledgeItems'
import { useShop, useQueryParams } from '../../../../hooks'

import { CCButton, TrboOutsideClick } from '../../../UI'

import { DataSourceDropdown } from './dataSource/DataSourceDropdown'

import './ImportButton.scss'

const FIVE_MINUTES = 1000 * 60 * 5

type Props = {
  onComplete: () => void
}

export function ImportButtonV2 ({ onComplete }: Props) {
  const [shop] = useShop()
  const { isUploading, loadUploadStatus, setDataSourceModalType, setDataSourceOptionModalType } = useKnowledgeItems()
  const [isCompleted, setIsCompleted] = useState(false)
  const [dropdownVisible, setDropdownVisible] = useState(false)
  const queryParams: { modal?: string} = useQueryParams()

  function toggleDropdownVisibility () {
    setDropdownVisible(!dropdownVisible)
  }

  function handleSelectDataSource (type: KnowledgeDataSourceType) {
    setDataSourceModalType(type)
    toggleDropdownVisibility()
  }

  useEffect(() => {
    if (!shop.secure_id) return

    loadUploadStatus()
  }, [shop])

  useEffect(() => {
    let interval

    if (isUploading) {
      setIsCompleted(true)
      interval = setInterval(loadUploadStatus, FIVE_MINUTES)
    } else clearInterval(interval)

    return () => clearInterval(interval)
  }, [isUploading])

  useEffect(() => {
    if (!isUploading && isCompleted) {
      setIsCompleted(false)
      onComplete()
    }
  }, [isUploading, isCompleted])

  useEffect(() => {
    if (queryParams.modal) {
      const [type, optionModalType] = queryParams.modal.split(':')
      setDataSourceModalType(type)
      setDataSourceOptionModalType(optionModalType)
    }
  }, [queryParams])

  return (
    <TrboOutsideClick
      isEnabled={dropdownVisible}
      onOutsideClick={toggleDropdownVisibility}
    >
      <label className="import-button-label">
        <CCButton
          iconRight
          id="import-csv"
          icon="chevron-down"
          iconVersion="6"
          iconWeight="solid"
          customClasses="create-btn"
          onClick={toggleDropdownVisibility}
        >
          New data source
        </CCButton>
        {dropdownVisible && (
          <DataSourceDropdown onSelect={handleSelectDataSource} />
        )}
      </label>
    </TrboOutsideClick>
  )
}
