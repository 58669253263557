// @flow

import React from 'react'
import toastr from 'toastr'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

import { showErrorMessages } from '../../utils/feedbackMessages'
import { ProductsService } from '../../services'
import { Product } from '../../models'
import { useAttributes, useProducts } from '../../hooks'
import {
  CCButton,
  CCDropdown,
  CCDynamicInput,
  CCEditableDropdown,
  CCModal,
  CCTextInput,
  CCToggle,
  type DropdownOption
} from '../UI'

import { InvalidWarning } from './InvalidWarning/InvalidWarning'
import { RecommenderEditor } from './RecommenderEditor/RecommenderEditor'

// $FlowFixMe
import 'react-tabs/style/react-tabs.scss'

type Props = {
  categories: Array<string>,
  onSubmit: Function,
  product: Product,
  setProduct: Function,
  isOpen: boolean,
  onRequestClose: Function
}

export function EditProductModal ({ categories = [], onSubmit, product, setProduct, isOpen, onRequestClose }: Props) {
  const { products } = useProducts()
  const [, attributes] = useAttributes()

  const isInvalid = Boolean(product.invalidated_at)

  function buildCategoryOptions (): Array<DropdownOption> {
    const options = categories.map((c) => { return { value: c, label: c } })
    options.unshift({ value: null, label: '--' })
    return options
  }

  function buildProductOptionsWithIdentifier (products: Array<Product>): Array<DropdownOption> {
    return products.map((p) => ({ value: p.product_identifier, label: p.product_identifier }))
  }

  function buildProductOptionsWithId (products: Array<Product>): Array<DropdownOption> {
    return products.map((p) => ({ value: p.id, label: p.product_identifier }))
  }

  function handleChange (param: string, type: string = 'any') {
    return (value: any) => {
      if (type === 'json') value = JSON.parse(value)
      // $FlowFixMe
      product[param] = value
      setProduct(new Product({ ...product }))
    }
  }

  async function handleFormSubmit () {
    onRequestClose()

    try {
      const p = await ProductsService.updateProduct(product.id, product)
      toastr.remove()
      toastr.success('Product updated.')
      onSubmit(p)
    } catch (err) {
      showErrorMessages(err)
      onSubmit(err.response.data.product)
    }
  }

  return <CCModal title={`Edit product ${product.product_identifier}`} width={700} isOpen={isOpen}>
    <Tabs>
      <TabList>
        <Tab>Product</Tab>
        <Tab>Recommender</Tab>
      </TabList>
      <TabPanel>
        <div className="cc-modal__field">
          {isInvalid &&
            <InvalidWarning
              invalidatedAt={product.invalidated_at}
              reasons={product.meta?.invalid_reasons}
            />}
          <div className="cc-modal__horizontal-input">
            <div className="cc-modal__field__label">
              Product ID
            </div>
            <div className="cc-modal__field__body">
              {product.product_identifier}
            </div>
          </div>
          <div className="cc-modal__horizontal-input">
            <div className="cc-modal__field__label">
              Name
            </div>
            <div className="cc-modal__field__body">
              <CCTextInput
                autoFocus
                value={product.title}
                errorMessage="Name is required"
                error={!product.title}
                onChange={handleChange('title')}/>
            </div>
          </div>
          <div className="cc-modal__horizontal-input">
            <div className="cc-modal__field__label">
              Brand
            </div>
            <div className="cc-modal__field__body">
              <CCTextInput
                value={product.brand}
                onChange={handleChange('brand')}/>
            </div>
          </div>
          <div className="cc-modal__horizontal-input">
            <div className="cc-modal__field__label">
              Price
            </div>
            <div className="cc-modal__field__body">
              <CCTextInput
                value={product.price}
                type="number"
                onChange={handleChange('price')}/>
            </div>
          </div>
          <div className="cc-modal__horizontal-input">
            <div className="cc-modal__field__label">
              Sale price
            </div>
            <div className="cc-modal__field__body">
              <CCTextInput
                value={product.sale_price}
                type="number"
                onChange={handleChange('sale_price')}/>
            </div>
          </div>
          <div className="cc-modal__horizontal-input">
            <div className="cc-modal__field__label">
              Image Url
            </div>
            <div className="cc-modal__field__body">
              <CCTextInput
                value={product.image_link}
                onChange={handleChange('image_link')}/>
            </div>
          </div>
          <div className="cc-modal__horizontal-input">
            <div className="cc-modal__field__label">
              Url
            </div>
            <div className="cc-modal__field__body">
              <CCTextInput
                value={product.link}
                onChange={handleChange('link')}/>
            </div>
          </div>
          <div className="cc-modal__horizontal-input">
            <div className="cc-modal__field__label">
              Category
            </div>
            <div className="cc-modal__field__body">
              <CCEditableDropdown
                options={buildCategoryOptions()}
                value={{ value: product.category, label: product.category }}
                onChange={(e) => handleChange('category')(e.value)}
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}/>
            </div>
          </div>
          <div className="cc-modal__horizontal-input">
            <div className="cc-modal__field__label">
              Stock
            </div>
            <div className="cc-modal__field__body">
              <CCTextInput
                type="number"
                value={product.stock}
                onChange={handleChange('stock')}/>
            </div>
          </div>
          <div className="cc-modal__horizontal-input">
            <div className="cc-modal__field__label">
              Master product
            </div>
            <div className="cc-modal__field__body">
              <CCDropdown
                allowBlank
                autoCompleteEnabled
                truncateAfter={10}
                options={buildProductOptionsWithId(products)}
                value={product.master_product_id}
                onChange={p => handleChange('master_product_id')(p.value)}
                flexible
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}/>
            </div>
          </div>
          <div className="cc-modal__horizontal-input">
            <div className="cc-modal__field__label">
              Similar products
            </div>
            <div className="cc-modal__field__body">
              <CCTextInput
                type="text"
                value={product.similar_products_identifiers}
                onChange={handleChange('similar_products_identifiers')}/>
            </div>
          </div>
          <div className="cc-modal__horizontal-input">
            <div className="cc-modal__field__label">
              Priority for Desktop
            </div>
            <div className="cc-modal__field__body">
              <CCTextInput
                type="number"
                value={product.prio_desktop}
                onChange={handleChange('prio_desktop')}/>
            </div>
          </div>
          <div className="cc-modal__horizontal-input">
            <div className="cc-modal__field__label">
              Priority for Mobile
            </div>
            <div className="cc-modal__field__body">
              <CCTextInput
                type="number"
                value={product.prio_mobile}
                onChange={handleChange('prio_mobile')}/>
            </div>
          </div>
          <div className="cc-modal__horizontal-input">
            <div className="cc-modal__field__label">
              Customer rating
            </div>
            <div className="cc-modal__field__body">
              <CCTextInput
                type="number"
                value={product.customer_rating}
                onChange={handleChange('customer_rating')}
                maxNumber={5}
                minNumber={0}/>
            </div>
          </div>
          <div className="cc-modal__horizontal-input">
            <div className="cc-modal__field__label">
              Number of customer ratings
            </div>
            <div className="cc-modal__field__body">
              <CCTextInput
                type="number"
                value={product.customer_rating_count}
                onChange={handleChange('customer_rating_count')}
                minNumber={0}/>
            </div>
          </div>
        </div>
      </TabPanel>
      <TabPanel>
        <div className="cc-modal__field">
          <div className="cc-modal__horizontal-input">
            <div className="cc-modal__field__label">
              Enabled for recommender
            </div>
            <div className="cc-modal__field__body">
              <CCToggle
                active={product.enabled_for_recommender || false}
                onToggle={handleChange('enabled_for_recommender')}/>
            </div>
          </div>
          <div className="cc-modal__horizontal-input">
            <RecommenderEditor
              subscriberAttributes={attributes}
              value={product.recommender_attributes}
              onChange={handleChange('recommender_attributes')}
            />
          </div>
          <div className="cc-modal__horizontal-input">
            <div className="cc-modal__field__label">
              Trigger products
            </div>
            <div className="cc-modal__field__body">
              <CCDropdown
                autoCompleteEnabled
                truncateAfter={10}
                options={buildProductOptionsWithIdentifier(products)}
                value={product.trigger_product_identifiers.map((pi) => ({ value: pi, label: pi }))}
                onChange={(p) => { handleChange('trigger_product_identifiers')(p.map(o => o.value)) }}
                flexible
                isMulti
                menuPortalTarget={document.body}
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}/>
            </div>
          </div>
          <div className="cc-modal__horizontal-input">
            <div className="cc-modal__field__label">
              Explanation
            </div>
            <div className="cc-modal__field__body"
                 style={{ whiteSpace: 'pre-wrap', border: '1px solid #dae4e6', borderRadius: '6px' }}>
              <CCDynamicInput
                multiLine
                isBlock
                text={product.description}
                onChange={handleChange('description')}/>
            </div>
          </div>
        </div>
      </TabPanel>
    </Tabs>
    <div className="cc-modal__footer">
      <CCButton onClick={onRequestClose}>
        Cancel
      </CCButton>

      <CCButton color="primary" onClick={handleFormSubmit}>Save</CCButton>
    </div>
  </CCModal>
}
