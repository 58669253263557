// @flow

import React from 'react'

import { Message } from '../../../../models'

type Props = {
  messages: Array<Message>
}

export function BlockReportTable ({ messages }: Props) {
  return (
    <div>
      <div className="fixed-table-toolbar"/>
      <div className="fixed-table-container" style={{ paddingBottom: '0px' }}>
        <div className="fixed-table-header" style={{ display: 'none' }}>
          <table/>
        </div>
        <div className="fixed-table-body">
          <table className="table-no-bordered table table-hover">
            <thead>
              <tr>
                <th style={{}} data-field="message">
                  <div className="th-inner ">Message</div>
                  <div className="fht-cell"/>
                </th>
                <th style={{}} data-field="subscriber">
                  <div className="th-inner ">User</div>
                  <div className="fht-cell"/>
                </th>
              </tr>
            </thead>
            <tbody>
            {messages.map((message, i) => (
              <tr key={message.id} className="no-border" data-index={i} data-toggle="collapse"
                  data-target={`.message_row${message.id}`}>
                <td style={{}}>
                  {message.to_s}
                </td>
                <td style={{}}>
                  <strong>{message.incount}</strong>
                </td>
              </tr>
            ))}
            </tbody>
          </table>
        </div>
        <div className="fixed-table-footer" style={{ display: 'none' }}>
          <table>
            <tbody>
            <tr/>
            </tbody>
          </table>
        </div>
        <div className="fixed-table-pagination" style={{ display: 'none' }}/>
      </div>
    </div>
  )
}
