// @flow

import _ from 'lodash'
import React from 'react'

import { CCTextInput } from '../../UI'
import { AttributeValueAutoComplete } from '../../Attributes/AttributeValueAutoComplete/AttributeValueAutoComplete'

type Props = {
  index: number,
  title: string,
  value: string,
  uuid: string,
  attributeId: number,
  handleValueChange: Function,
  handleValueDelete: Function
}

export function ValueEntry (props: Props) {
  return <>
    <div className="send-to-block__row__dropdown">
      <CCTextInput
        placeholder='title'
        customClasses={'col-md-6'}
        value={props.title}
        onChange={_.debounce((option) => {
          props.handleValueChange(props.index, {
            title: option,
            value: props.value,
            uuid: props.uuid
          })
        }, 2000)}
      />
      <AttributeValueAutoComplete
        customClasses={'col-md-6'}
        value={props.value}
        placeholder="value"
        attributeId={props.attributeId}
        onChange={(option) => {
          props.handleValueChange(props.index, {
            title: props.title,
            value: option,
            uuid: props.uuid
          })
        }}
      />
    </div>
    <div className="send-to-block__row__delete">
      <i
        onClick={() => props.handleValueDelete(props.index)}
        className="fa fa-trash"
      />
    </div>
  </>
}
