// @flow

import React from 'react'
import { SortableElement, SortableHandle } from 'react-sortable-hoc'
import classnames from 'classnames'

import { ButtonType } from '../../../models/Button'

const SortHandle = SortableHandle(() => <i className="message-buttons__button--active__sort-handle fa fa-bars"/>)

const getButtonStyles = (button) => {
  return classnames('message-buttons__button--active', `message-button-${button.id}`, {
    postback: button.button_type === ButtonType.POSTBACK || button.button_type === ButtonType.WEB_URL,
    'message-buttons__button--is-highlighted': !!button.data?.is_highlighted
  })
}

export const SortableButton = SortableElement(({ onClick, onDelete, button, renderTitle, disableDeleteButtons }) =>
  <div
    key={button.id}
    onClick={onClick(button.id)}
    className={getButtonStyles(button)}
  >
    <SortHandle/>
    {renderTitle ? renderTitle(button) : button.title}
    {!disableDeleteButtons && (
      <span
        role="delete-button"
        onClick={onDelete(button)}
        className="message-buttons__button--active__delete-handle"
      ><i className="fa fa-remove"/></span>
    )}
  </div>)
