// @flow

import React from 'react'

import { KnowledgeItem } from '../../../../../models'

import { ActionsCell } from './ActionsCell'
import { InputSourceCell } from './InputSourceCell'
import { StatusCell } from './StatusCell'
import { ChangedCell } from './ChangedCell'

type CellProps = {
  row: {
    original: KnowledgeItem
  }
}

export const QA_COLUMNS = [
  {
    accessor: 'question',
    Header: 'Question',
    Cell: ({ row }: CellProps) => (
      <div>
        <div className='k-table-question'>{row.original.question}</div>
        <div className='k-table-answer'>{row.original.answer}</div>
      </div>
    )
  },
  {
    accessor: 'updated_at',
    Header: 'Changed',
    Cell: ({ row }: CellProps) => (
      <ChangedCell knowledgeItem={row.original} />
    )
  },
  {
    accessor: 'status',
    Header: 'Status',
    Cell: ({ row }: CellProps) => (
      <StatusCell knowledgeItem={row.original} />
    )
  },
  {
    accessor: 'input_source',
    Header: 'Source',
    Cell: ({ row }: CellProps) => <InputSourceCell knowledgeItem={row.original} />
  },
  {
    accessor: 'actions',
    disableSortBy: true,
    Header: '',
    Cell: ActionsCell
  }
]
