// @flow

import _ from 'lodash'
import React, { useEffect, useState } from 'react'

import { CCButton, CCPageHeader, CCSpinner } from '../../UI'
import { guid } from '../../../utils/uuid'
import { useShop } from '../../../hooks'

import { Attribute } from '../../../models'
import { AttributesService } from '../../../services'
import { showErrorMessages } from '../../../utils/feedbackMessages'
import { AttributeRow } from '../AttributeRow/AttributeRow'

import './AttributesList.scss'

export function AttributesList () {
  const [attributes, setAttributes] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [shop] = useShop()

  useEffect(() => {
    async function fetchData () {
      setIsLoading(true)
      const attributes = await AttributesService.getAttributes(shop, ['id', 'name', 'recommender_type', 'weight', 'personal_data', 'deletable'])
      setAttributes(attributes)
      setIsLoading(false)
    }

    if (!shop.secure_id) return

    fetchData()
  }, [shop])

  const removeAttributeBy = (params: Object) => {
    const _attributes = [...attributes]
    const index = _.findIndex(_attributes, params)
    _attributes.splice(index, 1)
    setAttributes(_attributes)
  }

  const updateAttributeBy = (params: Object, data: Object) => {
    const _attributes = [...attributes]
    const index = _.findIndex(_attributes, params)
    _attributes[index] = data
    setAttributes(_attributes)
  }

  const handleAttributeCreateClicked = () => {
    setAttributes([...attributes, new Attribute({ uuid: guid() })])
  }

  const handleAttributeChanged = async (attribute: Attribute) => {
    if (attribute.id) {
      try {
        await AttributesService.updateAttribute(shop, attribute)
        updateAttributeBy({ id: attribute.id }, attribute)
      } catch (err) {
        showErrorMessages(err)
      }
    } else {
      try {
        const a = await AttributesService.createAttribute(shop, attribute)
        updateAttributeBy({ uuid: attribute.uuid }, a)
      } catch (err) {
        showErrorMessages(err)
        removeAttributeBy({ uuid: attribute.uuid })
      }
    }
  }

  const handleAttributeCancelClicked = (attribute: Attribute) => {
    if (!attribute.id) {
      removeAttributeBy({ uuid: attribute.uuid })
    }
  }

  const handleAttributeDeleteClicked = async (attribute: Attribute) => {
    if (!confirm('Are you sure you want to delete this attribute?')) return

    await AttributesService.deleteAttribute(attribute)
    removeAttributeBy({ id: attribute.id })
  }

  return <div className="subscriber-attributes">
    <div className="attributes-list">
      <CCPageHeader
        title="Attributes"
        description={
            `<span>Attributes are properties which are characterizing a chatbot/wizard user. The recommender algorithm uses them by matching the users attributes and the product attributes. Ideally, all attributes are created here before importing your product feed. It is also possible to set the recommender weight and type and declare whether they are personal data or not.</span>
              <br/>
              <span>Check out our
                <a target="_blank"
                  rel="noopener noreferrer"
                  href="https://guide.chatchamp.com/Attributes-d3f5828f267f46aeab9aa3b18eb20232">
                  attributes user guide
                </a>
                to learn more.
              </span>
            `}>
        <CCButton customClasses="create-btn" onClick={handleAttributeCreateClicked} icon="plus">
          Create New
        </CCButton>
      </CCPageHeader>
      {!isLoading && <div className="container">
        <table className="cc-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Attribute Type</th>
              <th>Attribute Weight</th>
              <th>Personal Data</th>
            </tr>
          </thead>
          <tbody>
          {attributes.map((attribute) => <AttributeRow
            onChange={handleAttributeChanged}
            onDelete={handleAttributeDeleteClicked}
            onCancel={handleAttributeCancelClicked}
            key={attribute.id || attribute.uuid}
            attribute={attribute}/>)}
          </tbody>
        </table>
      </div>}
      {isLoading &&
        <div className="spinner_block">
          <CCSpinner/>
        </div>}
    </div>
  </div>
}
