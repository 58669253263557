// @flow

import { Action, action, Thunk, thunk } from 'easy-peasy'
import { KnowledgeItemsService } from '../services'
import {
  KnowledgeItem,
  Shop,
  type KnowledgeDataSourceType,
  type KnowledgeDataSourceOptionType,
  KNOWLEDGE_ITEMS_PROGRESS_STATUS_VALUES
} from '../models'

type FetchPayload = {
  shop: Shop,
  params: {
    page: number
  }
}

type ImportFilePayload = {
  shop: Shop,
  file: File
}

type LoadUploadStatusPayload = {
  shop: Shop
}

type LoadProgressPayload = {
  shop: Shop
}

type SetKnowledgeItemsPayload = {
  shop: Shop
}

type setKnowledgeItemsPayload = {
  shop: Shop,
  id: number
}

type InputSourceCounter = {
  csv: number,
  feed_link: number,
  manual: number
}

type UploadStatus = 'in_progress' | 'completed'

type Content = 'data' | 'question'

export type KnowledgeItemsModel = {
  knowledgeItems: Array<KnowledgeItem>,
  pages: number,
  page: number,
  count: number,
  search: string | null,
  orderValue: string | null,
  orderDirection: string | null,
  isLoadingList: boolean,
  isLoadingAnswer: boolean,
  isUploading: boolean,
  uploadProgress: number,
  uploadStatus: UploadStatus | null,
  isCreating: boolean,
  isDeleting: boolean,
  dataSourceModalType: KnowledgeDataSourceType | null,
  dataSourceOptionModalType: KnowledgeDataSourceOptionType | null,
  isValidDataSourceModal: boolean,
  inputSourceCount: InputSourceCounter,
  content: Content,
  answer: string | null,
  setKnowledgeItems: Action<KnowledgeItemsModel, Array<KnowledgeItem>>,
  setIsLoadingList: Action<KnowledgeItemsModel, boolean>,
  setIsLoadingAnswer: Action<KnowledgeItemsModel, boolean>,
  setIsUploading: Action<KnowledgeItemsModel, boolean>,
  setUploadProgress: Action<KnowledgeItemsModel, number>,
  setUploadStatus: Action<KnowledgeItemsModel, UploadStatus>,
  setIsCreating: Action<KnowledgeItemsModel, boolean>,
  setIsDeleting: Action<KnowledgeItemsModel, boolean>,
  setDataSourceModalType: Action<KnowledgeItemsModel, KnowledgeDataSourceType>,
  setPages: Action<KnowledgeItemsModel, number>,
  setPage: Action<KnowledgeItemsModel, number>,
  setCount: Action<KnowledgeItemsModel, number>,
  setSearch: Action<KnowledgeItemsModel, string>,
  setOrderValue: Action<KnowledgeItemsModel, string>,
  setOrderDirection: Action<KnowledgeItemsModel, string>,
  setInputSourceCount: Action<KnowledgeItemsModel, InputSourceCounter>,
  setContent: Action<KnowledgeItemsModel, Content>,
  setAnswer: Action<KnowledgeItemsModel, string>,
  fetch: Thunk<KnowledgeItemsModel, FetchPayload>,
  importFile: Thunk<KnowledgeItemsModel, ImportFilePayload>,
  loadUploadStatus: Thunk<KnowledgeItemsModel, LoadUploadStatusPayload>,
  loadProgress: Thunk<KnowledgeItemsModel, LoadProgressPayload>,
  editKnowledgeItem: Thunk<KnowledgeItemsModel, setKnowledgeItemsPayload>,
  createManualEntry: Thunk<KnowledgeItemsModel, SetKnowledgeItemsPayload>,
  setUploadInProgress: Thunk<KnowledgeItemsModel>
}

export const knowledgeItemsModel: KnowledgeItemsModel = {
  knowledgeItems: [],
  pages: 0,
  page: 0,
  count: 0,
  search: null,
  orderValue: null,
  orderDirection: null,
  isLoadingList: true,
  isLoadingAnswer: false,
  isUploading: false,
  uploadProgress: 0,
  uploadStatus: null,
  isCreating: false,
  isDeleting: false,
  dataSourceModalType: null,
  dataSourceOptionModalType: null,
  isValidDataSourceModal: false,
  inputSourceCount: { csv: 0, feed_link: 0, manual: 0 },
  content: 'data',
  answer: null,
  setKnowledgeItems: action((state, payload) => {
    state.knowledgeItems = payload
  }),
  setIsLoadingList: action((state, payload) => {
    state.isLoadingList = payload
  }),
  setIsLoadingAnswer: action((state, payload) => {
    state.isLoadingAnswer = payload
  }),
  setPages: action((state, payload) => {
    state.pages = payload
  }),
  setPage: action((state, payload) => {
    state.page = payload
  }),
  setCount: action((state, payload) => {
    state.count = payload
  }),
  setIsUploading: action((state, payload) => {
    state.isUploading = payload
  }),
  setUploadProgress: action((state, payload) => {
    state.uploadProgress = payload
  }),
  setUploadStatus: action((state, payload) => {
    state.uploadStatus = payload
  }),
  setIsCreating: action((state, payload) => {
    state.isCreating = payload
  }),
  setIsDeleting: action((state, payload) => {
    state.isDeleting = payload
  }),
  setDataSourceModalType: action((state, payload) => {
    state.dataSourceModalType = payload
  }),
  setDataSourceOptionModalType: action((state, payload) => {
    state.dataSourceOptionModalType = payload
  }),
  setIsValidDataSourceModal: action((state, payload) => {
    state.isValidDataSourceModal = payload
  }),
  setSearch: action((state, payload) => {
    state.search = payload
  }),
  setOrderValue: action((state, payload) => {
    state.orderValue = payload
  }),
  setOrderDirection: action((state, payload) => {
    state.orderDirection = payload
  }),
  setInputSourceCount: action((state, payload) => {
    state.inputSourceCount = payload
  }),
  setContent: action((state, payload) => {
    state.content = payload
  }),
  setAnswer: action((state, payload) => {
    state.answer = payload
  }),
  fetch: thunk(async (actions, payload) => {
    actions.setIsLoadingList(true)
    const { knowledgeItems, pages, page, count } = await KnowledgeItemsService.getList(payload.shop, payload.params)
    actions.setKnowledgeItems(knowledgeItems)
    actions.setPages(pages)
    actions.setPage(page)
    actions.setCount(count)
    actions.setIsLoadingList(false)
  }),
  importFile: thunk(async (actions, payload) => {
    const formData = new FormData()
    formData.append('file', payload.file)
    actions.setUploadInProgress()
    await KnowledgeItemsService.import(payload.shop, formData)
  }),
  loadUploadStatus: thunk(async (actions, payload) => {
    const uploadStatus = await KnowledgeItemsService.loadUploadStatus(payload.shop)
    actions.setIsUploading(uploadStatus === KNOWLEDGE_ITEMS_PROGRESS_STATUS_VALUES.IN_PROGRESS)
  }),
  loadProgress: thunk(async (actions, payload) => {
    const progressStatus = await KnowledgeItemsService.loadProgress(payload.shop)
    actions.setUploadProgress(progressStatus.progress)
    actions.setUploadStatus(progressStatus.status)
  }),
  editKnowledgeItem: thunk(async (actions, payload) => {
    try {
      actions.setIsCreating(true)
      const result = await KnowledgeItemsService.editKnowledgeItem(payload.shop, payload.params.id, payload.params)
      actions.setIsCreating(false)
      actions.setUploadInProgress()
      if (!result) throw new Error('Error while editing Q&A entry, please try it again.')
    } catch (error) {
      actions.setIsCreating(false)
      throw error
    }
  }),
  createManualEntry: thunk(async (actions, payload) => {
    try {
      actions.setIsCreating(true)
      const result = await KnowledgeItemsService.createManualEntry(payload.shop, payload.params)
      actions.setIsCreating(false)
      actions.setUploadInProgress()
      if (!result) throw new Error('Q&A entry was not created successfully, please try it again.')
    } catch (error) {
      actions.setIsCreating(false)
      throw error
    }
  }),
  deleteKnowledgeItem: thunk(async (actions, payload) => {
    actions.setIsDeleting(true)
    const result = await KnowledgeItemsService.delete(payload.shop, payload.id)
    actions.setIsDeleting(false)
    if (!result) throw new Error('Q&A entry was not deleted, please try it again.')
  }),
  deleteKnowledgeItems: thunk(async (actions, payload) => {
    actions.setIsDeleting(true)
    const result = await KnowledgeItemsService.multipleDelete(payload.shop, payload.inputSources)
    actions.setIsDeleting(false)
    if (!result) throw new Error('Q&A entries were not deleted, please try it again.')
  }),
  loadInputSourceCount: thunk(async (actions, payload) => {
    const result = await KnowledgeItemsService.inputSourceCount(payload.shop)
    actions.setInputSourceCount(result)
    if (!result) throw new Error('Error fetching input source count.')
  }),
  retryUpload: thunk(async (actions, payload) => {
    const result = await KnowledgeItemsService.retryUpload(payload.shop)
    if (result) actions.setUploadInProgress()
    else throw new Error('It was not possible to retry now, please try it again.')
  }),
  askQuestion: thunk(async (actions, payload) => {
    actions.setIsLoadingAnswer(true)
    const result = await KnowledgeItemsService.askQuestion(payload.shop, payload.searchQuery)
    actions.setIsLoadingAnswer(false)
    if (result) actions.setAnswer(result)
  }),
  setUploadInProgress: thunk((actions) => {
    actions.setIsUploading(true)
    actions.setUploadProgress(0)
    actions.setUploadStatus(KNOWLEDGE_ITEMS_PROGRESS_STATUS_VALUES.IN_PROGRESS)
  })
}
