// @flow

import _ from 'lodash'
import React, { useEffect, useState } from 'react'

import { ATTRIBUTE_TYPE } from '@chatchamp/chatchamp-types'

import { CCButton, CCDropdown, CCTextInput, CCToggle, type DropdownOption } from '../../UI'

import { Attribute, isDefaultAttribute, RECOMMENDER_WEIGHTS } from '../../../models/Attribute'

type Props = {
  onCancel: Function,
  onSave: Function,
  attribute: Attribute,
}

export function AttributeRowForm (props: Props) {
  const [attribute, setAttribute] = useState(new Attribute(props.attribute))

  const nameRef: { current: null | HTMLDivElement } = React.createRef()

  useEffect(() => {
    if (nameRef.current) nameRef.current.focus()
  })

  const updateAttribute = (property: string) => {
    return (value: any) => {
      _.set(attribute, property, value)
      setAttribute(new Attribute(attribute))
    }
  }

  const handleSave = () => {
    props.onSave(attribute)
  }

  const handleKeyPress = (e: any) => e.key === 'Enter' && props.onSave(attribute)

  return <tr className="animated flipInX animation-duration-300">
    {(isDefaultAttribute(attribute.name))
      ? (<td>{attribute.name}</td>)
      : (<td>
        <CCTextInput
          placeholder="Name"
          innerRef={nameRef}
          value={attribute.name}
          onKeyPress={handleKeyPress}
          onChange={updateAttribute('name')}/>
        <span className="cc-table-label">Name</span>
      </td>)
    }
    <td>
      <CCDropdown
        options={Object.entries(ATTRIBUTE_TYPE).map(([key, value]) => ({ value: key, title: value }))}
        onChange={(item: DropdownOption) => updateAttribute('recommender_type')(item.value)}
        value={attribute.recommender_type}/>
      <span className="cc-table-label">Attribute Type</span>
    </td>
    <td>
      {!attribute.isNotRelevant() &&
        (<><CCDropdown
          options={RECOMMENDER_WEIGHTS}
          onChange={(option: Object) => updateAttribute('weight')(option.value)}
          value={attribute.weight}/>
          <span className="cc-table-label">Attribute Weight</span>
        </>)}
    </td>
    <td>
      <CCToggle
        onToggle={updateAttribute('personal_data')}
        active={attribute.personal_data}
      />
    </td>
    <td className="cc-table__actions">
      <CCButton
        onClick={handleSave} color="primary"
        customClasses="cc-table__action-button">
        <i className="fa fa-save margin-right-10"/>
        Save
      </CCButton>
      <CCButton onClick={props.onCancel} customClasses="cc-table__action-button">
        <i className="fa fa-close margin-right-10"/>
        Cancel
      </CCButton>
    </td>
  </tr>
}
