// @flow

import React from 'react'

import './CCActionPanel.scss'

type Props = {
  elements: Array<Object>,
  active: string,
  children: Array<any>,
  onTabChange: Function
}

export function CCActionPanel ({ elements, active, onTabChange, children }: Props) {
  if (!Array.isArray(children)) children = [children]

  return (
    <div>
      <div className="tab_container">
        {
          elements.map((element) => (
            <li className={['tab', 'small', active === element.value ? 'active' : ''].join(' ')}
                key={element.value}
                onClick={() => onTabChange(element.value)}
                role="tab-header-item">
              {element.title}
            </li>
          ))
        }
      </div>
      {children.map((child) => {
        if (child.props.value !== active) return null
        return (<div className="tab-content margin-top-20" value="block" key={active}>
          <div className="text_centered tab-pane active in">
            {child.props.children}
          </div>
        </div>)
      })
      }
    </div>
  )
}
