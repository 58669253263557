// @flow

import React from 'react'

import { TrboPageHeader } from '../../../UI'
import { FeatureFlag } from '../../../FeatureFlag/FeatureFlag'
import { useKnowledgeItems } from '../../../../hooks'

import { ImportButtonV2 } from './ImportButtonV2'

import './KnowledgeBaseListPageHeader.scss'

type Props = {
  loadKnowledgeItems: Function
}

export function KnowledgeBaseListPageHeader ({ loadKnowledgeItems }: Props) {
  const {
    content,
    setContent
  } = useKnowledgeItems()

  return (
    <div className="kb-list__header">
      <TrboPageHeader
        title="Knowledge Base"
        description='The knowledge base contains all relevant information for the customer service AI feature. The chatbot will only answer in the scope of the uploaded information.'
      >
        <ImportButtonV2 onComplete={loadKnowledgeItems} />
      </TrboPageHeader>
      <FeatureFlag showOnStaging>
        <div className="kb-list__header__tab-bar">
          <div
            className={content === 'data' ? 'kb-list__header__tab-bar__item-active' : 'kb-list__header__tab-bar__item'}
            onClick={() => setContent('data')}
          >
            My data
          </div>
          <div
            className={content === 'question' ? 'kb-list__header__tab-bar__item-active' : 'kb-list__header__tab-bar__item'}
            onClick={() => setContent('question')}
          >
            Ask a question
          </div>
        </div>
      </FeatureFlag>
    </div>
  )
}
