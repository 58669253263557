// no flow yet

import React, { useRef } from 'react'
import classnames from 'classnames'
import _ from 'lodash'
import Select, { components, InputProps, ValueContainerProps, MenuProps, SingleValueProps } from 'react-select'

import { type Props, formatOptionsForReactSelect, findSelectedOptionByValue } from './common'
import './search.scss'

import * as themes from './themes'

const Input = (props: InputProps) => {
  if (props.isHidden) {
    return <components.Input {...props} />
  }
  return (
    <components.Input {...props} className="cc-dropdown-search__custom-input"/>
  )
}

const ValueContainer = (props: ValueContainerProps) => {
  if (props.isHidden) {
    return <components.ValueContainer {...props} />
  }
  return (
    <div className="cc-dropdown-search__value-container">
      <div className="cc-dropdown-search__value-container__icon">
        <div className="fa fa-search"></div>
      </div>
      <div className="cc-dropdown-search__value-container__container">
        <components.ValueContainer {...props} className="cc-dropdown-search__value-container__component" />
      </div>
    </div>
  )
}

const Menu = (props: MenuProps) => {
  return (
    <components.Menu {...props} className="cc-dropdown-search__menu"/>
  )
}

const SingleValue = (props: SingleValueProps) => {
  return (
    <components.SingleValue {...props} className="cc-dropdown-search__single-value"/>
  )
}

export function CCDropdownSearch ({
  options = [],
  isLoading,
  onChange = () => {},
  onInputChange = () => {},
  value,
  theme = 'teal',
  allowBlank,
  styles,
  id,
  classNamePrefix,
  required,
  menuPortalTarget,
  customClasses = '',
  children
}: Props) {
  const ref = useRef()
  const containerStyles = required && _.isEmpty(value)
    ? { border: '1px #CE505F solid', borderRadius: 6 }
    : {}

  return (
    <div
      className={classnames('cc-dropdown', customClasses)}
      style={containerStyles}
      role="dropdown"
    >
      <Select
        ref={ref}
        autoFocus
        isLoading={isLoading}
        // value={{ label: }}
        styles={{ ...themes[theme], ...styles }}
        onInputChange={onInputChange}
        components={{
          ValueContainer,
          Menu,
          Input,
          SingleValue,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null
        }}
        formatOptionLabel={({ value, label, customAbbreviation }) => (
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              {label}
            </div>
            <div>
              <div className="fa fa-clipboard" style={{ marginRight: 8 }}></div>
              Shop
            </div>
          </div>
        )}
        menuIsOpen
        openMenuOnFocus
        options={formatOptionsForReactSelect(options, true, allowBlank)}
        closeMenuOnSelect={false}
        onChange={value => {
          onChange(value)
          setTimeout(() => {
            if (ref.current) ref.current.onMenuClose()
          })
        }}
        isMulti={false}
        id={id}
        placeholder={'Search shop...'}
        classNamePrefix={classNamePrefix}
        value={findSelectedOptionByValue(value, options, allowBlank)}
        required
        menuPortalTarget={menuPortalTarget}
      >
        {children}
        </Select>
    </div>
  )
}
