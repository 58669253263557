// @flow

import React from 'react'

import { KnowledgeItem } from '../../../../../../models'
import { useKnowledgeItems } from '../../../../../../hooks'

import { CCConfirmationModal, showInfoMessage } from '../../../../../UI'

import '../ActionsCell.scss'

type Props = {
  row: {
    original: KnowledgeItem
  },
  onDeleteRequestClose: Function,
  dataSource?: string
}

export function DeleteModal ({ row, onDeleteRequestClose, dataSource = 'qa' }: Props) {
  const { deleteKnowledgeItem, loadKnowledgeItems, loadInputSourceCount } = useKnowledgeItems()

  async function onDeleteConfirm () {
    if (await deleteKnowledgeItem(row.original.id)) {
      showInfoMessage(deleteToastText[dataSource].success)
      loadKnowledgeItems()
      loadInputSourceCount()
    }
    onDeleteRequestClose()
  }

  const messages = {
    qa: `Are you sure you want to delete "${row.original.question}" Q&A entry?`,
    website: `Are you sure you want to delete the trained website "${row.original.link}"?`
  }

  const deleteToastText = {
    qa: { success: 'Q&A entry deleted', error: 'It was not possible to delete Q&A entry' },
    website: { success: 'Website entry deleted', error: 'It was not possible to delete website entry' }
  }

  return (
    <CCConfirmationModal
      isShowing
      title="Delete confirmation"
      message={messages[dataSource]}
      confirmLabel="Delete"
      cancelLabel="Cancel"
      onConfirm={onDeleteConfirm}
      onCancel={onDeleteRequestClose}
    />
  )
}
