// @flow

import React, { useEffect, useState } from 'react'

import { DEVICES } from '@chatchamp/chatchamp-types'

import {
  CCButton,
  CCDateRangePicker,
  CCDropdown,
  CCPageHeader,
  CCSpinnerPage,
  CCTableWithSummary,
  showSuccessMessage,
  type DropdownOption,
  type CCTableColumn,
  Percentage
} from '../../UI'

import { WelcomeMessage } from '../../../models'
import { WelcomeMessageService } from '../../../services'
import { cookiesHandler, WELCOME_MESSAGE_ID_IN_CLIPBOARD } from '../../../utils/cookiesHandler'
import { DateFilter } from '../../../utils/dateFilter'
import { useShop, useWelcomeMessage } from '../../../hooks'

import { CCTableSearchFilter } from '../../UI/CCCustomizableTable/components/CCTableSearchFilter'
import { FolderFilter } from '../../Chatbots/pages/ChatbotList/FolderFilter'

import { EditWelcomeMessageModal } from '../modals/EditWelcomeMessageModal'
import { ActiveRenderer, ConnectedToRenderer, NameRenderer, TypeRenderer } from './renderers'
import { WelcomeMessageTableDropdownMenu } from './WelcomeMessageTableDropdownMenu'

import './WelcomeMessageList.scss'
import '../../Dashboard/Dashboard.scss'
// eslint-disable-next-line import/order
import styles from '../../UI/CCCustomizableTable/CCCustomizableTable.css'

const WELCOME_MESSAGE_COLUMNS: Array<CCTableColumn> = [
  { Header: 'Name', accessor: 'name', Cell: NameRenderer },
  { Header: 'Type', accessor: 'type', Cell: TypeRenderer },
  { Header: 'Connected to', accessor: 'campaign.name', Cell: ConnectedToRenderer },
  { Header: 'Message text', accessor: 'description', type: 'string' },
  { Header: 'Impr.', accessor: 'unique_impressions', type: 'number' },
  { Header: 'Activations', accessor: 'activations', type: 'number' },
  { Header: '%', accessor: (c: WelcomeMessage) => Percentage(c.activation_rate), type: 'percentage' },
  { Header: 'Active', accessor: 'active', Cell: ActiveRenderer }
]

function getColumns (filters: Object): Array<CCTableColumn> {
  if (filters?.folder?.value === 'active') return WELCOME_MESSAGE_COLUMNS

  return WELCOME_MESSAGE_COLUMNS.filter(column => column.Header !== 'Active')
}

export function WelcomeMessageList () {
  const [shop] = useShop()
  const [testWelcomeMessage] = useWelcomeMessage()

  const dateFilter: DateFilter = new DateFilter('growth_tools:analytics-date-range')
  const [welcomeMessages, setWelcomeMessages] = useState([])
  const [welcomeMessagesFiltered, setWelcomeMessagesFiltered] = useState([])
  const [summary, setSummary] = useState({})
  const [startDate, setStartDate] = useState(dateFilter.loadDateRangeFromSession().startDate)
  const [endDate, setEndDate] = useState(dateFilter.loadDateRangeFromSession().endDate)
  const [isLoading, setIsLoading] = useState(false)
  const [device, setDevice] = useState('any')
  const [welcomeMessage, setWelcomeMessage] = useState(new WelcomeMessage({}))
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isWelcomeMessageInClipboard, setIsWelcomeMessageInClipboard] = useState(false)
  const [filters, setFilters] = useState({})

  useEffect(() => {
    if (!shop.secure_id) return

    async function fetchOrUpdate (): Promise<void> {
      setIsLoading(true)

      const welcomeMessages = await WelcomeMessageService.getList(shop, startDate, endDate, device)
      setWelcomeMessages(welcomeMessages)

      setIsLoading(false)
      checkEditIdInUrl(welcomeMessages)
    }

    fetchOrUpdate()
  }, [startDate, endDate, device, shop.secure_id])

  async function getSummary (welcomeMessages: Array<WelcomeMessage>): Promise<void> {
    const summary = await WelcomeMessageService.getSummary(shop, welcomeMessages.map(gt => gt.id), startDate, endDate, device)
    setSummary(summary)
  }

  async function handleDuplicateClicked (welcomeMessage: WelcomeMessage): Promise<void> {
    setIsLoading(true)
    const duplicatedWelcomeMessage = await WelcomeMessageService.duplicate(shop, welcomeMessage.id)
    setWelcomeMessages([...welcomeMessages, duplicatedWelcomeMessage])
    setIsLoading(false)
  }

  async function handleDeleteClicked (welcomeMessage: WelcomeMessage): Promise<void> {
    if (!confirm('Are you sure?')) return

    setWelcomeMessages(welcomeMessages.filter(gt => gt.id !== welcomeMessage.id))
    await WelcomeMessageService.delete(welcomeMessage.id)
  }

  function handleEdited (welcomeMessage: WelcomeMessage): void {
    if (welcomeMessages.find(wm => wm.id === welcomeMessage.id)) {
      setWelcomeMessages(welcomeMessages.map(wm => (wm.id === welcomeMessage.id) ? welcomeMessage : wm))
    } else {
      setWelcomeMessages([...welcomeMessages, welcomeMessage])
    }
  }

  function handleEditClicked (welcomeMessage: WelcomeMessage): void {
    // $FlowFixMe
    setWelcomeMessage(welcomeMessage)
    setIsModalOpen(true)
  }

  function renderControls () {
    return (
        <React.Fragment>
          <FolderFilter
            updateFilter={updateFilter}
            folderFilters={[
              { value: 'active', label: 'In Use' },
              { value: 'archive', label: 'Archive' }
            ]}
          />
          <CCTableSearchFilter updateFilter={updateFilter}/>
        </React.Fragment>
    )
  }

  function handleCreateClicked (): void {
    // $FlowFixMe
    setWelcomeMessage(new WelcomeMessage({ widget_type: 'welcome_message', trigger: 'immediately' }))
    setIsModalOpen(true)
  }

  function onRequestClose (): void {
    setIsModalOpen(false)
  }

  function handleDateRangeChanged ({ startDate, endDate }: { startDate: Date, endDate: Date }): void {
    dateFilter.saveDateRangeInSession({ startDate, endDate })
    setStartDate(startDate)
    setEndDate(endDate)
  }

  function handleDeviceChange (item: DropdownOption): void {
    setDevice(item.value)
  }

  function handleChange (param: string): Function {
    return (value: Object): void => {
      // $FlowFixMe
      welcomeMessage[param] = value
      // $FlowFixMe
      setWelcomeMessage(new WelcomeMessage(welcomeMessage))
    }
  }

  async function handleDefaultClicked (welcomeMessage: WelcomeMessage): Promise<void> {
    if (welcomeMessage.default) {
      alert(`Welcome message ${welcomeMessage.name} is already set as the default.`)
      return
    }

    await WelcomeMessageService.markAsDefault(shop, welcomeMessage.id)
    const updatedList = welcomeMessages.map((growthTool) => (new WelcomeMessage({ ...growthTool, default: false })))
    const index = updatedList.findIndex((e) => e.id === welcomeMessage.id)
    updatedList[index].default = true
    setWelcomeMessages(updatedList)
  }

  async function updateFilter (name: string, filter: Function, value: string): Promise<void> {
    filters[name] = { filter, value }

    let filteredList = [...welcomeMessages]

    // $FlowFixMe
    for (const { filter } of Object.values(filters)) {
      // $FlowFixMe
      filteredList = filteredList.filter(filter)
    }

    if (JSON.stringify(filteredList) !== JSON.stringify(welcomeMessagesFiltered)) {
      setWelcomeMessagesFiltered(filteredList)
      setFilters(filters)
      await getSummary(filteredList)
    }
  }

  function checkEditIdInUrl (welcomeMessages): void {
    const url = new URL(window.location.href)
    const id = url.searchParams.get('edit')
    if (id) {
      const welcomeMessage = welcomeMessages.find(wm => Number(wm.id) === Number(id))
      if (welcomeMessage) handleEditClicked(welcomeMessage)
    }
  }

  function handleCopyClicked (welcomeMessage: WelcomeMessage): void {
    cookiesHandler.set(WELCOME_MESSAGE_ID_IN_CLIPBOARD, welcomeMessage.id.toString())
    setIsWelcomeMessageInClipboard(true)
    showSuccessMessage('Copied welcome message to clipboard')
  }

  async function handleUnarchiveClicked (welcomeMessage: WelcomeMessage) {
    await WelcomeMessageService.update(shop, welcomeMessage.id, { folder: null })

    welcomeMessages[welcomeMessages.findIndex((e) => e.id === welcomeMessage.id)].folder = null

    setWelcomeMessages([...welcomeMessages])
  }

  async function handleArchiveClicked (welcomeMessage: WelcomeMessage) {
    if (welcomeMessage.active === true) {
      if (!confirm('This Welcome Message is active. If you archive it it will be deactivated. Do you want to proceed?')) return
    }

    await WelcomeMessageService.update(shop, welcomeMessage.id, { folder: 'archive', active: false })

    const welcomeMessageToUpdate = welcomeMessages[welcomeMessages.findIndex((e) => e.id === welcomeMessage.id)]

    welcomeMessageToUpdate.folder = 'archive'
    welcomeMessageToUpdate.active = false

    setWelcomeMessages([...welcomeMessages])

    setIsLoading(false)
  }

  async function pasteWelcomeMessage (): Promise<void> {
    setIsLoading(true)
    const copiedWelcomeMessage = await WelcomeMessageService.pasteWelcomeMessageFromClipboard(shop, parseInt(cookiesHandler.get(WELCOME_MESSAGE_ID_IN_CLIPBOARD)))
    cookiesHandler.remove(WELCOME_MESSAGE_ID_IN_CLIPBOARD)
    setIsWelcomeMessageInClipboard(false)
    setWelcomeMessages([...welcomeMessages, copiedWelcomeMessage])
    setIsLoading(false)
    showSuccessMessage('Welcome message pasted successfully')
  }

  function renderList (): any {
    return (
      <div className="container growth-tools-container">
        <CCTableWithSummary
          data={welcomeMessagesFiltered}
          columns={getColumns(filters)}
          onRowClick={handleEditClicked}
          onRowDelete={handleDeleteClicked}
          controls={renderControls()}
          summaryRow={summary}
          dropdownMenu={WelcomeMessageTableDropdownMenu({
            handleTestClicked: testWelcomeMessage,
            handleDuplicateClicked,
            handleDefaultClicked,
            handleCopyClicked,
            handleUnarchiveClicked,
            handleArchiveClicked
          })}
        />
      </div>
    )
  }

  useEffect(() => {
    setIsWelcomeMessageInClipboard(!!cookiesHandler.get(WELCOME_MESSAGE_ID_IN_CLIPBOARD))
  }, [])

  return (
    <div className="growth-tools-list">
      <CCPageHeader
        customClasses="growth-tools-list__page-header"
        title="Welcome Messages"
        description={`
            <span>Setup welcome message to draw attention of your website visitors to your chatbots. A welcome message can be activated for different URLs via a regular expression of an URL Path.</span>
            <br/>
            <span>An A/B test will automatically consider all active welcome message which fit to the current page except for the default welcome message.</span>
            <br/>
            <span>Check out our
              <a target="_blank"
                rel="noopener noreferrer"
                href="https://guide.chatchamp.com/Welcome-Messages-6c6ae7fc1c5b49cc9bfa3911a783129f">
                  welcome message user guide
              </a>
              to learn more.
            </span>`}
        right={
          <div>
            <span className="header-label">Dates:</span>
            <CCDateRangePicker
              startDate={startDate}
              endDate={endDate}
              maxDate={new Date()}
              onChange={handleDateRangeChanged}
            />
            <span style={{ marginLeft: '10px' }} className="header-label">Device:</span>
            <div className={styles.device_select}>
              <CCDropdown
                theme="white"
                options={Object.entries(DEVICES).map(([device]) => { return { value: device, label: device } })}
                value={device}
                onChange={handleDeviceChange}/>
            </div>
          </div>
        }
      >
        <CCButton color="primary" onClick={handleCreateClicked} icon="plus">
          Create New
        </CCButton>
      </CCPageHeader>

      {(isLoading) ? (<CCSpinnerPage/>) : renderList()}
      <EditWelcomeMessageModal
        welcomeMessage={welcomeMessage}
        onSubmit={handleEdited}
        isOpen={isModalOpen}
        onRequestClose={onRequestClose}
        handleChange={handleChange}
      />

      {isWelcomeMessageInClipboard && (
        <div className="container">
          <div className="row margin-top-20">
            <div className="col-sm-12">
              <CCButton onClick={pasteWelcomeMessage}>Paste Welcome Message</CCButton>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
