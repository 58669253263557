// @flow

import _ from 'lodash'
import React, { useRef } from 'react'
import { useStoreActions, useStoreState } from 'easy-peasy'

import { Wizard, WizardPage } from '../../../../models'
import { BlockService } from '../../../../services'
import { showErrorMessages } from '../../../../utils/feedbackMessages'
import { CCDynamicInput, CCTooltip, CCToggle, CCActionDropdown, CCActionDropdownMenuItem } from '../../../UI'

import './Header.scss'

type Props = {
  page: WizardPage,
  onChange: Function,
  onSave: Function
}

export function Header (props: Props) {
  const wizard = useStoreState((state) => state.wizard.wizard)
  const updateWizard = useStoreActions((actions) => actions.wizard.updateWizard)
  const { page, onChange, onSave } = props
  const pageTitleRef = useRef()
  const pageInternalNameRef = useRef()

  async function handleDuplicatePage () {
    try {
      const { block } = await BlockService.duplicate(page, wizard.id)
      window.location.href = `/wizards/${wizard.id}/pages/${block.id}`
    } catch (e) {
      showErrorMessages(e)
    }
  }

  function handleChangeStartPage () {
    updateWizard(new Wizard({ ...wizard, start_page_id: page.id }))
  }

  return (
    <div className="page-edit-header">
      <h1 className="page-edit-header-title">Page Settings</h1>
      <div className="page-edit-header-settings">
        <div className="page-setting">
          <div className="page-setting__label">
            Page Title
            <CCTooltip
              className='page-setting__tooltip'
              title={'It is visible in the progress bar of the wizard.'}
              arrowPosition='left'
            />
          </div>
          <div className="page-setting__content">
            <CCDynamicInput
              id="page-title"
              className="page-setting__content-input"
              enabled={true}
              onChange={onChange('name')}
              placeholder="Page title"
              text={page.name}
              multiLine={false}
              innerRef={pageTitleRef}
              onBlur={onSave}
              onPressEnterKey={_.debounce(onSave, 500)}
            />
            <i
              className="fa fa-pencil page-setting__header-icon"
              onClick={() => pageTitleRef.current && pageTitleRef.current.focus()}
            />
          </div>
        </div>
        <div className="page-setting">
          <div className="page-setting__label">
            Internal Page Name
            <CCTooltip
              className='page-setting__tooltip'
              title={'It is only used internally to enable a better structure in the pages tree on the left side and is not visible to the end-user.'}
              arrowPosition='left'
            />
          </div>
          <div className="page-setting__content">
            <CCDynamicInput
              id="page-name"
              className="page-setting__content-input"
              enabled={true}
              onChange={onChange('internal_name')}
              placeholder="Page name"
              text={page.internal_name}
              multiLine={false}
              innerRef={pageInternalNameRef}
              onBlur={onSave}
              onPressEnterKey={_.debounce(onSave, 500)}
            />
            <i
              className="fa fa-pencil page-setting__header-icon"
              onClick={() => pageInternalNameRef.current && pageInternalNameRef.current.focus()}
            />
          </div>
        </div>
        {page.isHideSkipButtonAvailable() && (
          <div className="page-setting">
            <div className="page-setting__label">
              Hide skip button
            </div>
            <div className="page-setting__content">
              <CCToggle
                active={page.hide_skip_button}
                onToggle={onChange('hide_skip_button', true)}
                customClasses="page-setting__content-skip-button-toggle"
              />
            </div>
          </div>
        )}
        <div className="page-setting">
          <CCActionDropdown
            handle={
              <div className="page-setting__actions">
                <i className="fa fa-ellipsis-h"/>
              </div>
            }
          >
            <CCActionDropdownMenuItem
              onClick={handleDuplicatePage}
              icon="clone"
              title="Duplicate"
            />
            {!wizard.isStartPage(page) &&
              <CCActionDropdownMenuItem
                onClick={handleChangeStartPage}
                icon="list-ol"
                title="Set as start page"
              />
            }
          </CCActionDropdown>
        </div>
      </div>
    </div>
  )
}
