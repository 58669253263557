// @flow

import { useEffect, useState } from 'react'

import { showErrorMessage } from '../components/UI'

import { User } from '../models'
import { UserService } from '../services'

export function useUser () {
  const [user, setUser] = useState<any>(new User())

  useEffect(() => {
    async function loadUser () {
      try {
        const user = await UserService.getCurrentUser()
        setUser(user)
      } catch (e) {
        if (e.response?.status === 429) {
          showErrorMessage('Could not retrieve user information. Please wait some time and try again.')
        } else {
          showErrorMessage(e.message)
        }
      }
    }

    loadUser()
  }, [])

  return { user, setUser }
}
